.Page404 {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: black;
  display: flex;
  span {
    margin: auto;
    font-size: 100px;
    color: white;
  }
}
